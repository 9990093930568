import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroWithOverlay from "../components/Hero/HeroWithOverlay";
import EstatePlanningGuide from "../components/Repeating/EstatePlanningGuide";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import FAQs from "../components/Repeating/FAQ";

const Page = ({ data }) => {
   const heroImages = [
      getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
      {
         ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
         media: `(max-width: 767px)`,
      },
   ];

   return (
      <Layout>
         <SearchEngineOptimization
            title="Small Business Attorney San Diego | Conscious Legal"
            description="If you need a top small business attorney in San Diego, Conscious Legal is here to help with expert business advising services. Schedule a consultation!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroWithOverlay
            image={heroImages}
            backgroundPosition="50% 0%"
            heading={["Business Advising"]}
            subtext="We have built a reputation on furnishing high-quality legal advice."
            nextBackgroundColor="bg-primary-50"
         ></HeroWithOverlay>

         <section className="bg-primary-50 pb-20 md:pb-28">
            <div className="container">
               <div className="grid md:grid-cols-2 md:gap-x-10 lg:gap-x-20 gap-y-10 items-center">
                  <div>
                     <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} alt="About Alanna’s Mission" />
                  </div>
                  <div>
                     <h2>A Top Business Advising Attorney in California</h2>
                     <p className="text-mobile-lg md:text-lg mb-0">
                        The first step in successfully launching a business in California is to design the entity that best reflects your needs and
                        values. Alongside our estate planning services, our flat-fee entity formation and counseling package includes the tools and
                        education needed to create your strategy for success. We focus on drafting conscious contracts – integrative legal agreements
                        that reflect the values and mission of our clients, and serve as the vehicle for developing strong business relationships.
                     </p>
                  </div>
               </div>
            </div>
         </section>

         <WhyUs className="pt-24 md:pt-16 mb-18 md:mb-28" headingLevel="h2" />

         <section className="mb-18 md:mb-36">
            <div className="container">
               <div className="grid md:grid-cols-2 md:gap-x-10 lg:gap-x-20 gap-y-10 items-center mb-20 md:mb-28">
                  <div className="order-2 md:order-1">
                     <h2>How It Works</h2>
                     <p className="text-mobile-lg md:text-lg mb-0">
                        We love working with small business owners. After all, we’re business owners ourselves! And we get it: you’re busy, you’re
                        growing, and you desire a life of prosperity and ease. It can be hard to manage all your many different hats, while still
                        getting the advice and guidance you need. That’s where we come in. As your trusted business legal advisor, we’ll help you do
                        things right the first time, so you can stop worrying about the things “you don’t know you don’t know,” and start putting your
                        energy and attention where it rightfully should be: on putting your meaningful work into the world—the work that only you are
                        uniquely here to do.
                     </p>
                  </div>
                  <div className="order-1 md:order-2">
                     <GatsbyImage image={data.howItWorks.childImageSharp.gatsbyImageData} alt="How it Works" />
                  </div>
               </div>

               <div className="grid md:grid-cols-2 md:gap-x-10 lg:gap-x-20 gap-y-10 items-center mb-20 md:mb-28">
                  <div>
                     <GatsbyImage image={data.step1.childImageSharp.gatsbyImageData} alt="Business Legal Roadmap Session" />
                  </div>
                  <div>
                     <h3>1. Business Legal Roadmap Session</h3>
                     <p className="mb-4">
                        <i>*complimentary for clients in our VIP Client Membership Program</i>
                     </p>
                     <p className="text-mobile-lg md:text-lg mb-0">
                        We’ll start with clarity by getting to know the inner workings of your business through a Business Legal Roadmap Session.
                        We'll review your legal, insurance, financial, and tax ("LIFT") foundations, answer the burning questions you’ve been dying to
                        ask a lawyer, and ensure you've got everything you need to stay protected, stay compliant, and reach your higher ground.
                        Together, we'll create a legal roadmap to fill any leaky holes we’ve identified, and be sure you’ve got a game plan to operate
                        at your highest level of service.
                     </p>
                  </div>
               </div>

               <div className="grid md:grid-cols-2 md:gap-x-10 lg:gap-x-20 gap-y-10">
                  <div className="order-2 md:order-1">
                     <h3>2. Custom Legal Package</h3>
                     <p className="text-mobile-lg md:text-lg">
                        If you need additional support beyond the Business Legal Roadmap Session, we’ll craft a custom legal package for you with
                        different options designed with your needs in mind. You’ll pick your level of service and legal fee based on the intersection
                        of your goals and budget.
                     </p>
                     <p className="text-mobile-lg md:text-lg">
                        We work with a team of like-minded advisors that operate with the same heart of service that we do, to ensure sure we don’t
                        just cover your legal needs, but also the equally important insurance, financial, and tax pillars of your life and business.
                        We’ll serve as your business quarterback, building and guiding the team you need to succeed.
                     </p>

                     <ul className="text-mobile-xl md:text-xl text-gray-800 mt-10 md:mt-14">
                        <li className="flex items-center mb-4 md:mb-5">
                           <img src={data.checkmark.publicURL} alt="Checkmark" className="mr-4" /> Entity Formation and Maintenance
                        </li>
                        <li className="flex items-center mb-4 md:mb-5">
                           <img src={data.checkmark.publicURL} alt="Checkmark" className="mr-4" /> Making Contracts and Getting Deals Done
                        </li>
                        <li className="flex items-center mb-4 md:mb-5">
                           <img src={data.checkmark.publicURL} alt="Checkmark" className="mr-4" /> Hiring Team Members the Right Way
                        </li>
                        <li className="flex items-center mb-4 md:mb-5">
                           <img src={data.checkmark.publicURL} alt="Checkmark" className="mr-4" /> Dispute Negotiation and Resolution
                        </li>
                        <li className="flex items-center mb-4 md:mb-5">
                           <img src={data.checkmark.publicURL} alt="Checkmark" className="mr-4" /> Protecting your Trademarks and Intellectual
                           Property
                        </li>
                        <li className="flex items-center mb-4 md:mb-5">
                           <img src={data.checkmark.publicURL} alt="Checkmark" className="mr-4" /> Business Succession Planning
                        </li>
                        <li className="flex items-center">
                           <img src={data.checkmark.publicURL} alt="Checkmark" className="mr-4" />
                           <div>
                              General and Ongoing Legal Advising
                              <br />
                              <span className="text-sm md:text-lg">
                                 (for those times when you just wish you had a trusted legal advisor you could call to talk out issues or get advice!)
                              </span>
                           </div>
                        </li>
                     </ul>
                  </div>
                  <div className="order-1 md:order-2">
                     <GatsbyImage image={data.step2Desktop.childImageSharp.gatsbyImageData} alt="Custom Legal Package" className="hidden md:block" />
                     <GatsbyImage image={data.step2Mobile.childImageSharp.gatsbyImageData} alt="Custom Legal Package" className="md:hidden" />
                  </div>
               </div>
            </div>
         </section>

         <Testimonials headingLevel="h2" />

         <EstatePlanningGuide className="py-16 md:py-28" headingLevel="h2" />

         <About headingLevel="h2" />

         <CallToAction headingLevel="h2" />

         <FAQs className="md:pt-16 pb-16 md:pb-24" headingLevel="h2" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Business Advising_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Business Advising_TW.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "services/business-advising/1.0 Hero - desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      heroMobile: file(relativePath: { eq: "services/business-advising/1.0 Hero business - mobile.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      intro: file(relativePath: { eq: "services/business-advising/2.0 Business intro - desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      howItWorks: file(relativePath: { eq: "services/business-advising/3.0 business - desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      step1: file(relativePath: { eq: "services/business-advising/4.0 Business desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      step2Desktop: file(relativePath: { eq: "services/business-advising/5.0 business desktop.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      step2Mobile: file(relativePath: { eq: "services/business-advising/5.0 business - mobile.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      checkmark: file(relativePath: { eq: "global/custom-checkmark.svg" }) {
         publicURL
      }
   }
`;
export default Page;
